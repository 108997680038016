import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useForm } from 'react-hook-form';

import Icon from '../../../Icon'
import Input from '../../../RHF/Input';
import SelectInput from '../../../RHF/SelectInput';
import DateInput from '../../../RHF/DateInput';
import PriceInput from '../../../RHF/PriceInput';
import { serializeFloat as m } from '../../../../utils/money';

const deserializeExpense = (expense, { countries, currencies, accounts }) => {
  const account = accounts.find((a) => (a.value === expense.expected_on_account_id));
  const country = countries.find((c) => (c.value === expense.country));
  const currency = { value: expense.currency, label: expense.currency };

  return ({
    ...expense,
    date: new Date(expense?.date),
    expected_on_account_id: account,
    country,
    currency,
    net_amount: (expense?.net_amount?.cents || 0) / 100,
    amount: (expense?.amount?.cents || 0) / 100,
    withholding_tax: (expense?.withholding_tax?.cents || 0) / 100,
    other_withholding_tax: (expense?.other_withholding_tax?.cents || 0) / 100,
    sign: expense.sign || 1,
  });
};

const serializeExpense = (rawExpense) => {
  const country = rawExpense.country?.value;
  const currency = rawExpense.currency?.value;
  const expected_on_account_id = rawExpense.expected_on_account_id?.value;
  const date = rawExpense.date;

  const net_amount = m(rawExpense.net_amount, currency);
  const amount = m(rawExpense.amount, currency);
  const sign_adjusted_amount =  m(rawExpense.amount * rawExpense.sign, currency);
  const withholding_tax = m(rawExpense.withholding_tax || 0, currency);
  const other_withholding_tax = m(rawExpense.other_withholding_tax || 0, currency);

  return ({
    ...rawExpense,
    country,
    currency,
    date,
    expected_on_account_id,
    net_amount,
    amount,
    sign_adjusted_amount,
    withholding_tax,
    other_withholding_tax,
  });
};

const SummaryExpectationForm = ({
  countries,
  currencies,
  accounts,
  formId,
  initialValues,
  onSave,
}) => {
  const defaultValues = deserializeExpense(initialValues, { countries, currencies, accounts });
  const { handleSubmit, control, formState: { errors } } = useForm({ defaultValues });

  const onSubmit = (params) => {
    const { amount } = params;

    const expense = serializeExpense({
      ...params,
      ...amount,
    });

    onSave(expense);
  };

  return (
    <div>
      <Form onSubmit={handleSubmit(onSubmit)} id={formId}>
        <Input
          label="Headline"
          name="headline"
          errors={errors}
          control={control}
          size="lg"
          required
        />

        <div className="row">
          <div className="col-6">
            <DateInput
              label="Expected on date"
              name="date"
              errors={errors}
              control={control}
              required
            />
          </div>

          <div className="col-6">
            <SelectInput
              label="Account"
              name="expected_on_account_id"
              errors={errors}
              control={control}
              options={accounts}
              size="lg"
              required
            />
          </div>
        </div>

        <PriceInput
          title="asd"
          name="amount"
          errors={errors}
          control={control}
          showWithholdingFields={false}
          layout={false}
          net={defaultValues.net_amount}
          netOptions={{
            name: 'net_amount',
            label: 'Expense amount',
          }}
          vat={0}
          vatOptions={{
            readOnly: true,
            column: 'd-none',
            name: 'amount_vat',
          }}
          gross={defaultValues.amount}
          grossOptions={{
            auto: true,
            readOnly: true,
            name: 'amount',
            column: 'd-none',
          }}
          withholdingTaxOptions={{
            name: 'withholding_tax'
          }}
          otherWithholdingTaxOptions={{
            name: 'other_withholding_tax'
          }}
        />

        <div className="row">
          <div className="col-6">
            <SelectInput
              label="Country"
              name="country"
              errors={errors}
              control={control}
              options={countries}
              size="lg"
              required
            />
          </div>

          <div className="col-6">
            <SelectInput
              label="Currency"
              name="currency"
              errors={errors}
              control={control}
              options={currencies}
              size="lg"
              required
            />
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <Input
              label="From"
              name="counterpart"
              errors={errors}
              control={control}
              size="lg"
              required
            />
          </div>

          <div className="col-6">
            <Input
              label="Related to"
              name="related_to"
              errors={errors}
              control={control}
              size="lg"
            />
          </div>
        </div>

        <Input
          label="Notes"
          name="notes"
          errors={errors}
          control={control}
          size="lg"
          as="textarea"
        />
      </Form>
    </div>
  );
};

SummaryExpectationForm.propTypes = {
  initialValues: PropTypes.shape({}),
  onSave: PropTypes.func,
};

SummaryExpectationForm.defaultProps = {
  initialValues: {},
  onSave: console.log,
};

export default SummaryExpectationForm;
