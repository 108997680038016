import React, { useState } from 'react';
import { Chart } from 'react-google-charts';
import { isPast, addMinutes, subMinutes, format } from 'date-fns';
import { sortBy } from 'lodash';

import CustomLegend from '../../../charts/CustomLegend';

const columns = [
  {
    // 0
    type: 'date',
    label: 'Date',
  },
  {
    // 1
    type: 'number',
    label: 'Maximum revenue',
  },
  {
    // 2
    type: 'number',
    label: 'Expected revenue',
  },
  {
    // 3
    type: 'number',
    label: 'Past',
  },
  {
    // 4
    type: 'number',
    label: 'Sold tickets revenue',
  },
  {
    // 5
    type: 'number',
    label: 'Cumulative sold tickets revenue',
  },
  {
    // 6
    type: 'number',
    label: 'Ticket sales expectations',
  },
  {
    // 7
    type: 'number',
    label: 'Cumulative tickets sales',
  },
  {
    // 8
    type: 'number',
    label: 'Expected expenses',
  },
];

const buildRowDatum = ({ 
  date,
  maxCapacity,
  expectedTicketSales,
  past,
  soldTickets,
  cumulativeSoldTickets,
  ticketSales,
  cumulativeTicketSales,
  expenses,
}) => ([
  date,                          // 0
  maxCapacity,                   // 1  
  expectedTicketSales,           // 2
  past,                          // 3
  soldTickets || 0,              // 4
  cumulativeSoldTickets,         // 5
  ticketSales || 0,  // 6
  cumulativeTicketSales,         // 7
  expenses || 0,                 // 8
]);

const soldTicketsIdx = 4;
const cumulativeSoldTicketsIdx = 5;
const ticketSalesExpectationsIdx = 6;
const cumulativeTicketSalesIdx = 7;
const expensesIdx = 8;

const series = [
  { type: 'line', color: 'purple' }, // Max Capacity
  { type: 'line', color: 'orange' }, // Expected ticket sales
  { type: 'area', color: 'gray' },   // Past
  {
    color: 'green',
    type: 'bar',
    isStacked: false,
  }, // Sold Tickets
  { type: 'area', color: 'lightgreen' }, // Cumulative sold tickets
  {
    color: 'blue',
    type: 'bar',
    isStacked: false,
  }, // Ticket sales expectations
  { type: 'area', color: 'lightblue' }, // Cumulative ticket sales
  {
    color: 'red',
    type: 'bar',
    isStacked: false,
  }, // Expenses
];

const date2Str = (date) => (format(date, 'yyyy-MM-dd'));
const atMidnight = (date) => (new Date(date2Str(date)));

const RevenuesChart = ({
  width,
  height,
  maxCapacity,
  expectedTicketSales,
  salesStartsOn,
  salesEndsOn,
  tickets,
  expectations,
  averagePrice,
}) => {
  const ticketSalesExpectations = expectations.filter(({ sign }) => (sign === 'in'));
  const expenseExpectations = expectations.filter(({ sign }) => (sign === 'out'));
  const ticketPrice = ((averagePrice || {}).cents || 0) / 100.0 // Float
  const [legendStates, setLegendStates] = useState(new Array(8).fill(true));
  const today = new Date();
  const brd = (date, { soldTickets, ticketSales, expenses }) => { 
    const pastTicketsAmount = maxCapacity * ticketPrice;
    const past = isPast(date) ? pastTicketsAmount : 0;

    return (buildRowDatum({
      date,
      maxCapacity: maxCapacity * ticketPrice,
      expectedTicketSales: expectedTicketSales * ticketPrice,
      past,
      soldTickets,
      ticketSales,
      expenses,
    }));
  };

  const rawRows = [
    brd(salesStartsOn, {}),
    brd(subMinutes(today, 10), {}),
    brd(addMinutes(today, 10), {}),
    brd(salesEndsOn, {}),
  ];

  const counters = {};
  const buildEmptyCounterStruct = () => ({ 
    soldTickets: 0, 
    ticketSales: 0,
    expenses: 0,
  });

  tickets.forEach(({ purchased_at, quantity, base_price, tax, ticketing_service_fee }) => {
    const date = purchased_at.split('T')[0];
    const price = (base_price.cents + ticketing_service_fee.cents) / 100.0;

    counters[date] = counters[date] || buildEmptyCounterStruct();
    counters[date].soldTickets += (price * quantity);
  });

  ticketSalesExpectations.forEach(({ sign_adjusted_amount, expected_on_date }) => {
    const date = expected_on_date;
    const price = (sign_adjusted_amount.cents) / 100.0;

    counters[date] = counters[date] || buildEmptyCounterStruct();
    counters[date].ticketSales += price;
  });

  expenseExpectations.forEach(({ sign_adjusted_amount, expected_on_date }) => {
    const date = expected_on_date;
    const price = (sign_adjusted_amount.cents) / 100.0;

    counters[date] = counters[date] || buildEmptyCounterStruct();
    counters[date].expenses += price;
  });

  // Push single sold ticket for each day
  Object.keys(counters).forEach((key) => {
    const date = new Date(key);
    const { soldTickets, ticketSales, expenses } = counters[key];

    rawRows.push(brd(date, { soldTickets, ticketSales, expenses }));
  });

  const sortedRows = sortBy(rawRows, (r) => (r[0]));

  // Estimate cumulative values
  const cumulativeCounters = { soldTickets: 0, ticketSales: 0 };
  const rows = sortedRows.map((row, idx) => {
    const currentSoldTickets = row[soldTicketsIdx];
    const currentTicketSalesExpectations = row[ticketSalesExpectationsIdx];
    const currentExpenses = row[expensesIdx];

    cumulativeCounters.soldTickets += currentSoldTickets;
    cumulativeCounters.ticketSales += (currentSoldTickets + currentTicketSalesExpectations + currentExpenses);

    const newRow = [...row];

    newRow[cumulativeSoldTicketsIdx] = cumulativeCounters.soldTickets;
    newRow[cumulativeTicketSalesIdx] = cumulativeCounters.ticketSales;

    return newRow;
  });

  const handleLegendClick = (index) => {
    setLegendStates((items) => {
      const newItems = [...items];
      newItems[index] = !newItems[index];

      return newItems;
    });
  };

  const legendEntries = columns.slice(1).map(({ label }, index) => ({
    label,
    key: index,
    active: legendStates[index],
    color: series[index].color,
  }));

  const activeColumns = columns.reduce((newColumns, column, index) => {
    if (index === 0) { 
      // Always return the date columns
      newColumns.push(column); 

      return newColumns;
    }

    const isColumnActive = legendStates[index - 1];

    if (isColumnActive) {
      newColumns.push(column); 
    }

    return newColumns;
  }, []);

  const activeRows = rows.map((row) => {
    return row.reduce((newRow, value, index) => {
      if (index === 0) { 
        // Always return the date columns
        newRow.push(value); 

        return newRow;
      } 

      const isValueActive = legendStates[index - 1];

      if (isValueActive) {
        newRow.push(value); 
      }

      return newRow;
    }, []);
  });

  // NOTE: series does not include date
  const activeSeries = series.reduce((newSeries, serie, index) => {
    const isSerieActive = legendStates[index];

    if (isSerieActive) {
      newSeries.push(serie); 
    }

    return newSeries;
  }, []);


  const options = {
    legend: 'none',
    vAxis: {
      title: 'Revenues / Expenses',
      style: 'currency',
      format: '\u20AC#',
    },
    backgroundColor: '#f8f9fa',
    seriesType: 'bars',
    series: activeSeries,
    chartArea: {
      width: '80%',
      height: '80%',
    },
  };

  return (
    <div className="RevenuesChart">
      <Chart
        chartType="ComboChart"
        width={width}
        height={height}
        rows={activeRows}
        columns={activeColumns}
        options={options}
      />

      <CustomLegend
        items={legendEntries}
        onClick={(key) => { handleLegendClick(key); }}
        className="mt-3"
      />
    </div>
  );
};

RevenuesChart.defaultProps = {
  width: '100%',
  height: '600px',
};

export default RevenuesChart;
