import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import ExpectedTicketSalesUpdateModal from './ExpectedTicketSalesUpdateModal';
import { eventShape } from '../../utils';
import { reload } from '../../../../utils/router';
import Icon from '../../../Icon';
import { success } from '../../../../utils/notifier';

const ExpectedTicketSalesInplaceInput = ({ event, className }) => {
  const [showModal, setShowModal] = useState(false);
  const hideModal = () => { setShowModal(false); };
  const { expected_ticket_sales, details_path } = event;
  const handleExpectedTicketSalesUpdate = ({ response }) => {
    reload().then(() => { success({ message: response.message }); });
  };
  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  return (
    <div className={classnames('ExpectedTicketSalesInplaceInput', className)}>
      <ExpectedTicketSalesUpdateModal
        show={showModal}
        event={event}
        onHide={hideModal}
        onUpdate={handleExpectedTicketSalesUpdate}
      />

      <a href="#minimum-attendance" onClick={handleClick}>
        <span className="editable">{expected_ticket_sales || 'N/A'}</span>
        &nbsp;
        <Icon name="pencil-alt" fw />
      </a>
    </div>
  );
};

ExpectedTicketSalesInplaceInput.propTypes = {
  event: eventShape.isRequired,
};

ExpectedTicketSalesInplaceInput.defaultProps = {};

export default ExpectedTicketSalesInplaceInput;
