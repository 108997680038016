import React from 'react';
import Money from '../utils/money';
import Icon from './Icon';
import ColorizedDeadline from './ColorizedDeadline';

import PriceValue from './expectations/PriceValue';

class ExpectationsCalendarRow extends React.Component {
  generate_url(id = null, action = null) {
    let url = this.props.direction === 'in'
      ? '/financial_analysis/income_expectations/'
      : '/financial_analysis/expense_expectations/';
    if (id) url = url + id + '/';
    if (action) url = url + action;
    return url
  }

  icon_for(expectation_type) {
    let icon_string = '';
    if (expectation_type === 'single-shot') icon_string = 'fa fa-arrow-down';
    if (expectation_type === 'recurring') icon_string = 'fa fa-repeat';
    return icon_string;
  }

  render() {
    const { item } = this.props;
    const id =               item.id;
    const headline =         item.headline;
    const currency =         item.currency;
    const counterpart =      item.counterpart;
    const expected_amount =  item.expected_amount;
    const adjusted_amount =  item.sign_adjusted_amount;
    const expected_on_date = item.expected_on_date;
    const expectation_type = item.expectation_type;
    const state =            item.state;

    return (
      <tr key={id} className="row">
        <td className="text-right col-lg-3">
          <PriceValue expectation={item} />
        </td>

        <td className="col-lg-4">
          <div className="text-truncate" style={{ maxWidth: 150 }}>
            <a href={this.generate_url(id)} title={headline}>{headline}</a>
            <br />
            <small>{counterpart}</small>
          </div>
        </td>

        <td className="col-lg-2 text-center">
          <h5>
            <ColorizedDeadline deadline={expected_on_date} />
          </h5>
        </td>

        <td className="col-lg-3 text-center">
          <a
            href={this.generate_url(id, 'register_complete')}
            className="btn btn-ghost-success btn-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="Mark as fulfilled"
            data-method="post"
            data-original-title="Mark as fulfilled"
            data-confirm-disabled="Are you sure?"
          >
            <Icon name="check" fw />
          </a>

          <a
            href={this.generate_url(id, 'edit')}
            className="btn btn-ghost-info btn-sm"
            data-toggle="tooltip"
            data-placement="top"
            title="Edit expectation"
            data-original-title="Edit expectation"
          >
            <Icon name="edit" fw />
          </a>

          <a
            href={this.generate_url(id)}
            className="btn btn-ghost-dark btn-sm"
            data-toggle="tooltip"
            data-placement="top"
            data-method="delete"
            data-confirm="Are you sure?"
            title="Delete expectation"
            data-original-title="Delete expectation"
          >
            <Icon name="trash" fw />
          </a>
        </td>
      </tr>
    );
  }
}

export default ExpectationsCalendarRow;
