import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _ from 'lodash';

import Icon from '../Icon';
import styles from './styles/CustomLegend.module.scss';

const ItemShape = PropTypes.shape({
  tooltip: PropTypes.string,
  label: PropTypes.string,
  key: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
});

function CustomLegendItem({ color, id, onClick, active, label, tooltip }) {
  const handleClick = () => {
    onClick(id);
  }

  return (
    <div
      role="button"
      key={id}
      className={classnames(
        styles.CustomLegendItem,
        { [styles.CustomLegendItemActive]: active },
      )}
      onClick={handleClick}
      aria-hidden="true"
    >
      <div className="d-flex align-items-center">
        <div
          className={classnames(styles.CustomLegendItemColor, 'w-25 text-center')}
          style={{ backgroundColor: color }}
          title={tooltip}
        >
          {
            active
              ? (<Icon name="check" fw />)
              : (<Icon name="times" fw />)
          }
        </div>

        <div
          className={classnames(styles.CustomLegendItemText, 'w-75 px-2')}
        >
          <div className="no-text-truncate">{label}</div>
        </div>
      </div>
    </div>
  );
}

function CustomLegend({ items, onClick, themes, className }) {
  const classNames = classnames(
    styles.CustomLegend,
    'px-5',
    className,
    {
      [styles.CustomLegendCentered]: _.includes(themes, 'centered'),
      [styles.CustomLegendResponsive]: _.includes(themes, 'responsive'),
    },
  );

  return (
    <div className={classNames}>
      {items.map((item) => {
        const {
          label, key, color, active,
        } = item;

        return (
          <CustomLegendItem
            label={label}
            key={key}
            id={key}
            color={color}
            onClick={onClick}
            active={active}
          />
        );
      })}
    </div>
  );
}

CustomLegend.propTypes = {
  onClick: PropTypes.func,
  items: PropTypes.arrayOf(ItemShape),
  themes: PropTypes.arrayOf(PropTypes.oneOf([
    'default', 'centered', 'responsive',
  ])),
};

CustomLegend.defaultProps = {
  onClick: () => {},
  items: [],
  themes: ['default', 'responsive'],
};

export default CustomLegend;
