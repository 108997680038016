import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import AddExpectationButton from './AddExpectationButton';
import Icon from '../../Icon';
import PriceValue from '../../expectations/PriceValue';
import Money, { colorizeMoney, formatMoney } from '../../../utils/money';

const colorizeDeadline = (deadline) => {
  if (!deadline) {
    return 'badge badge-danger';
  }

  if (Date.parse(deadline) <= (Date.now() - 1)) {
    return 'badge badge-danger';
  } else if (Date.parse(deadline) < (Date.now() + 2)) {
    return 'badge badge-warning';
  } else {
    return 'badge badge-light';
  }
};

const ExpectationRow = ({ item, className, variant, compact, view, badge }) => {
  const { id, headline, counterpart, expected_on_date, state, direction } = item;
  const finalVariant = variant || (direction === 'in' ? 'success' : 'danger');

  return (
    <div className={classnames(className)}>
      <h6 className="mb-0">
        <div className="my-1">
          <span className={colorizeDeadline(expected_on_date)}>
            {expected_on_date || 'Missing date'}
          </span>
          &nbsp;
          <span className={classnames('text-uppercase', 'badge', `badge-${finalVariant}`)}>
            {badge}
          </span>

          {
            state === 'FULFILLED' && (
              <>
                &nbsp;
                <span className="badge badge-success">PAID</span>
              </>
            )
          }
        </div>

        <strong className={classnames(`text-${finalVariant}-no`)}>{headline}</strong>
        <br />
        <small>{counterpart}</small>
      </h6>

      {
        (
          !compact && (
            <PriceValue expectation={item} />
          )
        )
      }

      <hr className={classnames({ 'd-none': compact })}/>
    </div>
  );
};

const SummaryCaption = ({
  unreconciledExpectations,
  plannedExpectations,
  newExpectations,

  onNewExpectation,
  currencies,
  countries,
  accounts,
  compact,
  view,
}) => {
  const unreconciledTotal = unreconciledExpectations.reduce((prev, curr) => {
    const currMoney = Money(curr.sign_adjusted_amount || {});
    return prev.add(currMoney);
  }, Money({ cents: 0 }));

  const plannedTotal = plannedExpectations.reduce((prev, curr) => {
    const currMoney = Money(curr.sign_adjusted_amount || {});
    return prev.add(currMoney);
  }, Money({ cents: 0 }));

  const newExpectationsTotal = newExpectations.reduce((prev, curr) => {
    const currMoney = Money(curr.sign_adjusted_amount || {});
    return prev.add(currMoney);
  }, Money({ cents: 0 }));

  const total = unreconciledTotal.subtract(plannedTotal).add(newExpectationsTotal);

  const showTotal = unreconciledExpectations.length > 0
    || plannedExpectations.length > 0
    || newExpectations.length > 0;

  const newExpecvariantVariant = view === 'invoices' 
    ? 'success' 
    : 'danger';

  return (
    <div>
      {
        unreconciledExpectations.map((item) => (
          <ExpectationRow
            key={item.id}
            item={item}
            variant="primary"
            compact={compact}
            badge="Unreconciled"
          />
        ))
      }

      <div className="clearfix" />

      {
        plannedExpectations.map((item) => (
          <ExpectationRow
            key={item.id}
            item={item}
            className="text-right"
            variant="warning"
            compact={compact}
            badge="Planned"
          />
        ))
      }

      <div className="clearfix" />

      {
        newExpectations.map((item) => (
          <ExpectationRow
            key={item.id}
            item={item}
            compact={compact}
            badge={`New expectation`}
          />
        ))
      }

      {
        showTotal && !compact && (
          <div>
            <AddExpectationButton
              view={view}
              countries={countries}
              currencies={currencies}
              accounts={accounts}
              onExpectationSave={onNewExpectation}
            />

            <hr />

            <div className="row">
              <div className="col-sm-12 text-nowrap text-center small">
                <strong className="text-primary">
                  {formatMoney(unreconciledTotal)}
                </strong>
                &nbsp;
                -
                &nbsp;
                <strong className="text-warning">
                  {formatMoney(plannedTotal)}
                </strong>
                {
                  (newExpectations.length > 0 && (
                    <>
                      &nbsp;
                      + 
                      &nbsp;
                      <strong className={`text-${newExpecvariantVariant}`}>
                        {formatMoney(newExpectationsTotal)}
                      </strong>
                    </>
                  ))

                }
              </div>
            </div>
          </div>
        )
      }

      {
        showTotal && (
          <div>
            <hr />

            <div className="row">
              <div className="col-md-6">
                <h6 className="text-left mb-0 text-nowrap">
                  Difference:
                </h6>
              </div>

              <div className="col-md-6">
                <h6 className="text-right mb-0">
                  <strong className="text-nowrap">
                    {colorizeMoney(total)}
                  </strong>
                </h6>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

SummaryCaption.defaultProps = {
  compact: false,
  newExpectations: [],
};

export default SummaryCaption;
