import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from 'react-bootstrap/Button';

import ExpectedTicketSalesInplaceInput from './ExpectedTicketSalesInplaceInput';
import ExpectedAverageTicketPriceInplaceInput from './ExpectedAverageTicketPriceInplaceInput';
import ExpectedCostPerAttendeeInplaceInput from './ExpectedCostPerAttendeeInplaceInput';
import ExpectedTrainerMinimumFeeInplaceInput from './ExpectedTrainerMinimumFeeInplaceInput';
import ExpectedTrainerRevenuePercentageInplaceInput from './ExpectedTrainerRevenuePercentageInplaceInput';
import ExpectedExpensesEditButton from './ExpectedExpensesEditButton';

import ForecastCharts from './ForecastCharts';
import TicketClassesList from '../TicketClassesList';
import ListingTable from '../../../ListingTable/Infinite';
import Row from '../../../ExpectationsCalendarRow';
import PriceValue from '../../../expectations/PriceValue';
import { eventShape } from '../../utils';
import Spinner from '../../../Spinner';
import Icon from '../../../Icon';
import ColorizedDeadline from '../../../ColorizedDeadline';
import ConfirmButton from '../../../ConfirmModalButton';
import handleError from '../../../../utils/error-handler';
import { success } from '../../../../utils/notifier';
import Money, { formatRubyMoney } from '../../../../utils/money';
import { planSalesForecasts } from '../../../../api/forecast/public_training_sales';

const endpoint = (id) => (`/forecast/planned_event_expectations/${id}/calendar.json`);

const ExpectedExpensesListRow = ({ expense }) => {
  const { headline, date, counterpart } = expense;

  return (
    <tr className="row">
      <td className="text-right col-lg-3">
        <PriceValue expectation={expense} />
      </td>

      <td className="col-lg-6">
        <div className="text-truncate" style={{ maxWidth: 150 }}>
          <h6 className="mb-0">
            {headline}
            <br />
            <small>{counterpart}</small>
          </h6>
        </div>
      </td>

      <td className="col-lg-3 text-center">
        <h5>
          <ColorizedDeadline 
            deadline={date}
          />
        </h5>
      </td>
    </tr>
  );
};

const ExpectedExpensesList = ({ expenses }) => (
  <table className="table table-striped table-sm mt-2">
    <tbody>
      {
        expenses.map((expense) => (
          <ExpectedExpensesListRow 
            key={expense.id}
            expense={expense}
          />
        ))
      }
    </tbody>
  </table>
);

const ForecastPage = ({ 
  event, 
  forecastExpectations, 
  tickets, 
  loading, 
  countries,
  accounts,
  currencies,
}) => {
  if (loading) {
    return (
      <div>
        <Spinner
          description="Loading public training forecast settings..."
        />
      </div>
    );
  }

  const [isLoading, setIsLoading] = useState(false);
  const publicTrainingId = event.id;
  const {
    expected_average_gross_price,
    expected_average_net_price,
    expected_average_vat_percent,
    expected_ticket_sales,
    expected_expenses,
    initial_capacity,
    ticket_classes,
  } = event;

  const expectedExpenses = expected_expenses || [];

  const handleExpectationGeneration = () => {
    if (isLoading) { return; }

    setIsLoading(true);

    const params = {
      publicTrainingId,
      gross: expected_average_gross_price,
      net: expected_average_net_price,
      vatPercent: expected_average_vat_percent,
      ticketSales: expected_ticket_sales,
    };

    planSalesForecasts(params)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        // onHide();
        // onUpdate({ plannedEventId, response: data });
      })
      .catch(handleError);
  };

  const headers = ['Ticket name', 'Date', 'Price'];
  const sortableColumns = {};

  return (
    <div className={classnames('forecast-page')}>
      <div className="row">
        <div className="col-md-4">
          <h3 className="mb-3">
            Expected sales configurations
          </h3>

          <dl>
            <dt>
              <Icon name="chair" fw />
              &nbsp;
              Max capacity
            </dt>
            <dd className="">
              { `${initial_capacity} seats` }
            </dd>

            <dt>
              <Icon name="dollar-sign" fw />
              &nbsp;
              Number of sold tickets
            </dt>
            <dd className="">
              {tickets.length} sold tickets
            </dd>

            <dt>
              <Icon name="users" fw />
              &nbsp;
              Total number of saleable tickets expected
            </dt>
            <dd className="">
              <ExpectedTicketSalesInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="layer-group" fw />
              &nbsp;
              Ticket classes
            </dt>
            <dd className="">
              {
                ticket_classes.length === 0 && (
                  <p>No configured ticket classes</p>
                )
              }
              {
                ticket_classes.length > 0 && (
                  <TicketClassesList ticketClasses={ticket_classes} />
                )
              }
            </dd>

            <dt>
              <Icon name="ticket-alt" fw />
              &nbsp;
              Average ticket price
            </dt>
            <dd className="">
              <ExpectedAverageTicketPriceInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="money-bill-alt" fw />
              &nbsp;
              Cost per attendee
            </dt>
            <dd className="">
              <ExpectedCostPerAttendeeInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="chalkboard-teacher" fw />
              &nbsp;
              Trainer minimum fee
            </dt>
            <dd className="">
              <ExpectedTrainerMinimumFeeInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <Icon name="percent" fw />
              &nbsp;
              Trainer revenue percentage 
            </dt>
            <dd className="">
              <ExpectedTrainerRevenuePercentageInplaceInput
                event={event}
                className="d-inline-block"
              />
            </dd>

            <dt>
              <ExpectedExpensesEditButton  
                event={event}
                className="float-right px-1 py-0"
                countries={countries}
                accounts={accounts}
                currencies={currencies}
              />

              <Icon name="wallet" fw />
              &nbsp;
              Planned expenses
            </dt>
            <dd className="">
              {
                expectedExpenses.length === 0 && (
                  <p>No expected expenses</p>
                )
              }
              {
                expectedExpenses.length > 0 && (
                  <ExpectedExpensesList expenses={expectedExpenses} />
                )
              }
            </dd>
          </dl>

          <div className="my-5">
            <ConfirmButton
              block
              size="lg"
              variant="success"
              onConfirm={handleExpectationGeneration}
              buttonCaption="Generate expectations"
              title="Generate expectations"
              description="Do you want to generate sales expectations for the training course?"
            />
          </div>
        </div>

        <div className="col-md-8">
          <ForecastCharts 
            expectations={forecastExpectations}
            tickets={tickets} 
            event={event}
          />
        </div>
      </div>

      <ListingTable
        tableClassName="mt-5"
        initialEndpoint={endpoint(publicTrainingId)}
        components={{ Row }}
        filterKeys={['headline', 'notes', 'counterpart']}
        headers={['Ticket sales expectations']}
        events={[
          'Forecast::Expectations::Events::PublicTrainingTicketSoldExpectationSet',
          'Forecast::Expectations::Events::ExpectationDeleted',
          'Forecast::Expectations::Events::ExpectationDeletedFromReconciliation',
          'Forecast::Expectations::Events::ExpectationFulfilled',
        ]}
        hideSearch
      />
    </div>
  );
};

ForecastPage.propTypes = {
  event: eventShape.isRequired,
  loading: PropTypes.bool,
};

ForecastPage.defaultProps = {
  loading: false,
};

export default ForecastPage;

