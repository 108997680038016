import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DateTime } from 'luxon';
import { format } from 'date-fns';


import { eventShape } from '../../utils';
import Icon from '../../../Icon';
import handleError from '../../../../utils/error-handler';
import { success } from '../../../../utils/notifier';
import { updateExpectedExpenses } from '../../../../api/trainings/planned_events_economics';
import { ticketShape } from '../../utils';
import Money from '../../../../utils/money';
import { reload } from '../../../../utils/router';
import UUID from '../../../../utils/uuid';

import ListingTable from '../../../ListingTable';
import Row from '../../../ListingTable/Row';
import PriceValue from '../../../expectations/PriceValue';
import SummaryExpectationForm from './SummaryExpectationForm';
import ColorizedDeadline from '../../../ColorizedDeadline';

const ExpenseRow = (props) => {
  const { item, onDelete, onEdit } = props;
  const { headline, counterpart, date } = item;

  return (
    <Row {...props}>
      <td className="text-right col-lg-2">
        <PriceValue expectation={item} />
      </td>

      <td className="col-lg-4">
        <div className="text-truncate">
          <h6 className="mb-0">
            {headline}
            <br />
            <small>{counterpart}</small>
          </h6>
        </div>
      </td>

      <td className="col-lg-2 text-center">
        <h5 className="mb-0">
          <ColorizedDeadline 
            deadline={date}
          />
        </h5>
      </td>

      <td className="col-lg-2 text-center">
        <div className="btn-group" role="group" aria-label="Basic example">
          <Button size="sm" variant="warning" onClick={() => { onEdit(item); }}>
            <Icon name="edit" fw />
          </Button>

          <Button size="sm" variant="danger" onClick={() => { onDelete(item); }}>
            <Icon name="trash" fw />
          </Button>
        </div>
      </td>
    </Row>
  );
};

const ExpensesDatatable = ({ expectedExpenses, onEdit, onDelete }) => {
  return (
    <ListingTable
      components={{ Row: ExpenseRow }}
      items={expectedExpenses || []}
      rowProps={{ onEdit, onDelete }}
      tableClassName="mb-0"
      hideSearch
    />
  );
};

const ListModal = ({ 
  show,
  expectedExpenses,
  isLoading,
  onAdd,
  onHide,
  onUpdate,
  onEdit,
  onDelete,
}) => (
  <Modal show={show} onHide={onHide} size="lg">
    <Modal.Header closeButton>
      <Modal.Title>
        <strong>Set expected training expenses</strong>
      </Modal.Title>
    </Modal.Header>

    <Modal.Body style={{ zIndex: 301 }} className="p-0">
      {
        expectedExpenses.length === 0 && (
          <div className="text-center p-5">
            <h5 className="mb-3">
              No planned expenses for the current training.
            </h5>

            <Button onClick={onAdd} size="lg" variant="warning">
              <Icon name="plus" fw />
              &nbsp;
              Add new expense
            </Button>
          </div>
        )
      }

      {
        expectedExpenses.length > 0 && (
          <ExpensesDatatable
            expectedExpenses={expectedExpenses}
            onDelete={onDelete}
            onEdit={onEdit}
          />
        )
      }
    </Modal.Body>

    <Modal.Footer style={{ zIndex: 300 }} className="d-block text-right">
      <Button onClick={onAdd} variant="warning" className="float-left">
        <Icon name="plus" fw />
        &nbsp;
        Add expense
      </Button>

      <Button variant="secondary" onClick={onHide}>
        Close and discard
      </Button>
      &nbsp;
      <Button variant="primary" onClick={onUpdate} disabled={isLoading}>
        <Icon name="check" fw />
        &nbsp;
        { isLoading ? 'Loading...' : 'Update expenses' }
      </Button>
    </Modal.Footer>
  </Modal>
);

const FormModal = ({ 
  show,
  isLoading,
  expense,
  onAdd,
  onHide,
  onSave,
  onBack,
  countries,
  accounts,
  currencies,
}) => {
  const formDomId = 'summary-expectation-form';

  return (
    <Modal show={show} onHide={onHide} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          <strong>Edit planned expense</strong>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body style={{ zIndex: 301 }}>
        <SummaryExpectationForm 
          formId={formDomId}
          initialValues={expense}
          countries={countries}
          accounts={accounts}
          currencies={currencies}
          onSave={onSave}
        />
      </Modal.Body>

      <Modal.Footer style={{ zIndex: 300 }} className="d-block text-right">
        <Button onClick={onBack} variant="light" className="float-left">
          <Icon name="arrow-left" fw />
          &nbsp;
          Back
        </Button>

        <Button variant="secondary" onClick={onHide}>Close</Button>
        &nbsp;
        <Button variant="primary" 
          disabled={isLoading}
          type="submit"
          form={formDomId}
        >
          <Icon name="check" fw />
          &nbsp;
          { isLoading ? 'Loading...' : 'Save expense' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const ExpectedExpensesUpdateModal = ({
  show,
  onHide,
  onUpdate,
  event,
  countries,
  accounts,
  currencies,
}) => {
  const initialExpectedExpenses = event.expected_expenses || [];
  const [isLoading, setIsLoading] = useState(false);
  const [editingExpense, setEditingExpense] = useState(null);
  const [expectedExpenses, setExpectedExpenses] = useState(initialExpectedExpenses);
  const plannedEventId = event.id;

  useEffect(() => {
    setExpectedExpenses(initialExpectedExpenses);
    setEditingExpense(null);
    setIsLoading(false);
  }, [show])

  const handleUpdate = () => {
    if (isLoading) { return; }
    setIsLoading(true);

    const expenses = expectedExpenses.map(({ date, ...expense }) => ({
      ...expense,
      date: typeof date === 'string' ? date : format(date, 'yyyy-M-d'),
    }));

    updateExpectedExpenses({ plannedEventId, expenses })
      .then((response) => response.json())
      .then((data) => {
        onUpdate({ plannedEventId, response: data });
        setIsLoading(false);
        onHide();
      })
      .catch(handleError);
  };

  const handleAddExpenseClick = () => {
    setEditingExpense({
      id: UUID.generate(),
      country: 'IT',
      currency: 'EUR',
      sign: -1,
    });
  };

  const handleBack = () => {
    setEditingExpense(null);
  };

  const handleSave = (expense) => {
    setExpectedExpenses((expenses) => {
      const filteredExpenses = expenses.filter(({ id }) => (id !== expense.id));

      return ([
        ...filteredExpenses,
        expense,
      ]);
    });

    setEditingExpense(null);
  };

  const handleEditExpense = (expense) => {
    setEditingExpense(expense);
  };

  const handleDeleteExpense = (expense) => {
    setExpectedExpenses((expenses) => {
      const filteredExpenses = expenses.filter(({ id }) => (id !== expense.id));

      return (filteredExpenses);
    });
  };

  const showListModal = editingExpense === null;

  if (showListModal) {
    return (
      <ListModal 
        show={show}
        expectedExpenses={expectedExpenses}
        isLoading={isLoading}
        onAdd={handleAddExpenseClick}
        onHide={onHide}
        onUpdate={handleUpdate}
        onEdit={handleEditExpense}
        onDelete={handleDeleteExpense}
      />
    );
  }

  return (
    <FormModal 
      show={show}
      expense={editingExpense}
      isLoading={isLoading}
      onAdd={handleAddExpenseClick}
      onHide={onHide}
      onSave={handleSave}
      onBack={handleBack}
      countries={countries}
      accounts={accounts}
      currencies={currencies}
    />
  )
};

ExpectedExpensesUpdateModal.propTypes = {
  show: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  event: eventShape.isRequired,
};

ExpectedExpensesUpdateModal.defaultProps = {
  show: false,
  onUpdate: () => {},
};

export default ExpectedExpensesUpdateModal;

