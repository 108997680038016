import { post } from '../base';

export const planSalesForecasts = ({
  publicTrainingId,
  gross,
  net,
  vatPercent,
  ticketSales,
}) => (
  post('/financial_analysis/public-training-sales.json', {
    public_training_sales: {
      public_training_id: publicTrainingId,
      net,
      gross,
      vat_percent: vatPercent,
      ticket_sales: ticketSales,
    },
  })
);

export default {
  planSalesForecasts,
};
