import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';

import Money from '../../../../utils/money';

import RevenuesChart from './RevenuesChart';
import TicketsChart from './TicketsChart';
import LiquidityForecastChart from '../../../LiquidityForecastChart';

const ForecastCharts = ({ tickets, expectations, event }) => {
  const initialTab = 'revenues';
  const [currentTab, setCurrentTab] = useState(initialTab);
  const {
    initial_capacity,
    created_at,
    starts_on,
    ends_on,
    expected_ticket_sales,
    expected_average_gross_price,
    // expected_average_net_price,
    // expected_average_vat_percent,
    expected_expenses,
  } = event;

  const salesStartsOn = new Date(created_at);
  const salesEndsOn = new Date(starts_on);
  const soldTicketsAmount = tickets.reduce((amount, ticket) => {
    const { gross_price } = ticket;
    const price = Money(gross_price);

    return amount.add(price);
  }, Money());

  const expectationsValues = {
    soldTickets: soldTicketsAmount,
  };

  return (
    <div className="ForecastCharts">
      <Tab.Container id="revenues-charts" defaultActiveKey={initialTab} onSelect={ (key) => { setCurrentTab(key); }}>
        <div className="pb-3">
          <Nav variant="pills">
            <Nav.Item>
              <Nav.Link eventKey="revenues">Revenues Chart</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="tickets">Tickets Chart</Nav.Link>
            </Nav.Item>

            <Nav.Item>
              <Nav.Link eventKey="default">Forecast Chart</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>

        <Tab.Content>
          <Tab.Pane eventKey="revenues">
            <RevenuesChart 
              averagePrice={expected_average_gross_price}
              maxCapacity={initial_capacity}             
              salesStartsOn={salesStartsOn}
              salesEndsOn={salesEndsOn}
              expectedTicketSales={expected_ticket_sales}
              tickets={tickets}
              expectations={expectations}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="tickets">
            <TicketsChart 
              maxCapacity={initial_capacity}
              salesStartsOn={salesStartsOn}
              salesEndsOn={salesEndsOn}
              expectedTicketSales={expected_ticket_sales}
              tickets={tickets}
              expectations={expectations}
            />
          </Tab.Pane>

          <Tab.Pane eventKey="default">
            <LiquidityForecastChart
              expectationsData={expectations}
              values={expectationsValues}
            />
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default ForecastCharts;
