import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import classnames from 'classnames';

import InfiniteListingTable from '../../ListingTable/Infinite';
import ListingTable from '../../ListingTable';
import Icon from '../../Icon';
import Row from './Row';
import ReconciliationRow from './ReconciliationRow';
import SummaryCaption from './SummaryCaption';

const events = [
  'Forecast::Expectations::Events::ExpectationSet',
  'Forecast::Expectations::Events::UnreconciledExpectationSet',
  'Forecast::Expectations::Events::Amended',
  'Forecast::Expectations::Events::ExpectationDeleted',
  'Forecast::Expectations::Events::ExpectationFulfilled',
  'Forecast::Expectations::Events::ExpectationDeletedFromReconciliation',
  'Forecast::Expectations::Events::ExpectationReconciled',
];

const ReconciliationForm = ({
  title,
  view,
  isLoading,
  unreconciledExpectationsUrl,
  plannedExpectationsUrl,
  onUnreconciledExpectationClick,
  onPlannedExpectationClick,
  unreconciledExpectations,
  plannedExpectations,
  processedReconciliations,
  reconciliations,
  onReconcile,
  onSubmit,
  onReconciliationCancellation,
  currencies,
  countries,
  accounts,
  onNewExpectation,
  newExpectations,
  onExpectationDelete,
}) => {
  const currentReconciliations = reconciliations.filter((item) => (item.view === view));
  const hiddenUnreconciledExpectationIds = [currentReconciliations, processedReconciliations].flatMap((recons) => (
    recons.flatMap((reco) => (
      reco.unreconciledExpectations.map(({ id }) => (id))
    ))
  ));

  const hiddenPlannedExpectationIds = [currentReconciliations, processedReconciliations].flatMap((recons) => (
    recons.flatMap((reco) => (
      reco.plannedExpectations.map(({ id }) => (id))
    ))
  ));

  const selectedUnreconciledExpectations = unreconciledExpectations.filter((item) => (
    view === 'invoices'
      ? item.direction === 'in'
      : item.direction === 'out'
  ));
  const selectedUnreconciledExpectationIds = selectedUnreconciledExpectations.map(({ id }) => (id));

  const selectedPlannedExpectations = plannedExpectations.filter((item) => (
    view === 'invoices'
      ? item.direction === 'in'
      : item.direction === 'out'
  ))
  const selectedPlannedExpectationIds = selectedPlannedExpectations.map(({ id }) => (id));

  const selectedNewExpectations = newExpectations.filter((item) => (
    view === 'invoices'
      ? item.direction === 'in'
      : item.direction === 'out'
  ))

  const canReconcile = !isLoading && (selectedUnreconciledExpectations.length > 0 || selectedPlannedExpectations.length > 0);
  const canSubmit = currentReconciliations.length > 0 && !isLoading;

  const handleReconcile = () => {
    onReconcile({
      view,
      unreconciledExpectations: selectedUnreconciledExpectations,
      plannedExpectations: selectedPlannedExpectations,
      newExpectations: selectedNewExpectations,
    });
  };

  const handleSubmit = () => {
    onSubmit({
      reconciliations: currentReconciliations,
      view
    });
  };

  const wrapperStyles = {
    minHeight: '400px',
    height: 'calc(100vh - 460px)',
    overflowX: 'hidden',
    overflowY: 'auto',
  };

  return (
    <div className="ReconciliationForm">
      <h4>{title}</h4>

      <hr />

      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-header bg-primary">
              <h5 className="card-title text-center mb-0">
                Unreconciled documents
              </h5>
            </div>

            <InfiniteListingTable
              idColumn="id"
              events={events}
              initialEndpoint={unreconciledExpectationsUrl}
              components={{ Row }}
              filterKeys={['headline', 'notes', 'counterpart']}
              onRowClick={onUnreconciledExpectationClick}
              rowProps={{
                currentIds:        selectedUnreconciledExpectationIds,
                hiddenIds:         hiddenUnreconciledExpectationIds,
                theme:             'primary',
                showDiscardButton: true,
                onExpectationDelete,
              }}
              wrapperStyles={wrapperStyles}
            />
          </div>
        </div>

        <div className="col-md-2">
          <div className="card">
            <div className="card-header">
              <h5 className="card-title text-center mb-0">Summary</h5>
            </div>

            <div className="card-body">
              {!canReconcile && (
                <p className="text-center mb-0">
                  Choose one ore more expectations to be reconciled
                </p>
              )}

              <SummaryCaption
                unreconciledExpectations={selectedUnreconciledExpectations}
                plannedExpectations={selectedPlannedExpectations}
                countries={countries}
                currencies={currencies}
                accounts={accounts}
                onNewExpectation={onNewExpectation}
                newExpectations={newExpectations}
                view={view}
              />
            </div>

            <div className="card-footer">
              <Button
                className={classnames('btn-block', { disabled: !canReconcile })}
                variant="warning"
                disabled={!canReconcile}
                onClick={handleReconcile}
              >
                Reconcile
              </Button>
            </div>
          </div>
        </div>

        <div className="col">
          <div className="card">
            <div className="card-header bg-warning">
              <h5 className="card-title text-center mb-0">
                Planned expectations
              </h5>
            </div>

            <InfiniteListingTable
              idColumn="id"
              events={events}
              initialEndpoint={plannedExpectationsUrl}
              components={{ Row }}
              filterKeys={['headline', 'notes', 'counterpart']}
              onRowClick={onPlannedExpectationClick}
              rowProps={{
                currentIds: selectedPlannedExpectationIds,
                hiddenIds:  hiddenPlannedExpectationIds,
                theme:      'warning',
                showDiscardButton: false,
                onExpectationDelete,
              }}
              wrapperStyles={wrapperStyles}
            />
          </div>
        </div>

        <div className="col">
          <div className="card">
            <div className="card-header bg-success">
              <h5 className="card-title text-center mb-0">
                Pending reconciliations
              </h5>
            </div>

            {
              currentReconciliations.length === 0 && (
                <div className="card-body">
                  <p className="text-center mb-0">
                    No pending reconciliations
                  </p>
                </div>
              )
            }

            <ListingTable
              components={{ Row: ReconciliationRow }}
              items={currentReconciliations}
              rowProps={{
                onCancel: onReconciliationCancellation
              }}
              hideSearch
            />

            <div className="card-footer text-right">
              <Button
                variant="success"
                disabled={!canSubmit}
                className={classnames({ disabled: !canSubmit })}
                onClick={handleSubmit}
              >
                {
                  isLoading
                    ? (<span><Icon name="spinner" spin fw /> Saving reconciliations...</span>)
                    : (<span><Icon name="check" fw /> Save reconciliations</span>)
                }
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReconciliationForm;
